$glow-color: #fff500;

.glowing-light {
  text-shadow: 0px 0px 15px $glow-color;
  color: $glow-color;
  animation: glowingLight 5s infinite;
}

@keyframes glowingLight {
  0% {
    text-shadow: 0px 0px 10px $glow-color;
  }
  25% {
    text-shadow: 0px 0px 15px $glow-color;
  }
  50% {
    text-shadow: 0px 0px 20px $glow-color;
  }
  75% {
    text-shadow: 0px 0px 15px $glow-color;
  }
  100% {
    text-shadow: 0px 0px 10px $glow-color;
  }
}
